*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


.page-foolter{
    position: relative;
    bottom:0;
    left:0;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    background: #5a0230;
}
.page-foolter .container{
    position: relative;
    width: 100%;
    background: #5a0230;
    font-size: 14pt;
    color: #fff;
}

.page-foolter .container a{
    transition: all ease-in-out 0.3s;
    color: white;
    margin: 10px;
}
.page-foolter .container a:hover{
    color: #fe202f;
    margin: 15px ;
}

.page-foolter .footer-copyright {
    width: 100%;
    height: auto;
    background: #2e012f;
    padding: 15px;
    padding-left: 10%;
    padding-right: 10%;
}

.page-foolter .footer-copyright .container-cop{
    font-size: 10pt;
    color: #fff;
    margin: 0;
    padding-bottom: 15px;
}




@media(min-width: 1200px) {
   
}
/* SCREEN TABLET */
@media(min-width: 801px) and (max-width: 1199px) {
    .page-foolter .container{
        position: relative;
        width: 100%;
        background: #5a0230;
        font-size: 11pt;
        color: #fff;
    }
    
    
    .page-foolter .footer-copyright {
        width: 100%;
        height: auto;
        background: #2e012f;
        padding: 10px;
        padding-left: 10%;
        padding-right: 10%;
    }
    
    .page-foolter .footer-copyright .container-cop{
        font-size: 8pt;
        color: #fff;
        margin: 0;
        padding-bottom: 15px;
    }
}
/* SCREEN TABLET */
@media(max-width: 800px) {
    .page-foolter .container{
        position: relative;
        width: 100%;
        background: #5a0230;
        font-size: 10pt;
        color: #fff;
    }
    
    
    .page-foolter .footer-copyright {
        width: 100%;
        height: auto;
        background: #2e012f;
        padding: 5px;
        padding-left: 10%;
        padding-right: 10%;
    }
    
    .page-foolter .footer-copyright .container-cop{
        font-size: 8pt;
        color: #fff;
        margin: 0;
        padding-bottom: 15px;
    }
}

@media(max-width: 600px){
    .page-foolter .container{
        position: relative;
        width: 100%;
        background: #5a0230;
        font-size: 5pt;
        color: #fff;
    }
    
    
    .page-foolter .footer-copyright {
        width: 100%;
        height: auto;
        background: #2e012f;
        padding: 2px;
        padding-left: 10%;
        padding-right: 10%;
    }
    
    .page-foolter .footer-copyright .container-cop{
        font-size: 5pt;
        color: #fff;
        margin: 0;
        padding-bottom: 15px;
    }


}

@media(max-width: 405px){
   
}