@import url('../../../assets/css/style.css');

.pesquisarBlog{
    width: 100%;
    min-height: 100vh;
    margin: 0px;
    background: var(--color-background);

}

.pesquisarBlog a{
    text-decoration: none;
    color: var(--colorBlack);
}

/* PROPAGANDA HEADER */
.pesquisarBlog .propaganda-header{
    width: 90%;
    height: 100px;
    margin: 10px auto;
}

.pesquisarBlog .artigos{
    width: 100%;
    min-height: 70vh;
    margin: auto;    
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    
}

.pesquisarBlog .artigos ul{
    border: none;
}

.pesquisarBlog .artigos h4{
    text-align: center;
    margin-left: 5%;
    font-weight: bold;
    
}

.pesquisarBlog .artigos h5{
    text-align: left;
    margin-left: 5%;
    font-weight: bold;
}

.pesquisarBlog .artigos li{
    padding: 5px;
    margin-top: 10px;
}

.pesquisarBlog .artigos .artigoEsquerdo li{  
    height: auto;
    padding-bottom: 30px; 
    border-bottom: 1px solid #ccc;
    text-align: left;
}

.pesquisarBlog .artigos .artigoEsquerdo{
    width: 96%;
    height: auto;
    margin-right: 2%;
}

.pesquisarBlog .artigos .artigoEsquerdo img{
    width: 100%;
    height: auto;
    border-radius: 0.2em;
    max-width: 100%;
	transition: all 0.3s;
    -moz-transform: scale(0.98);
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
}

.pesquisarBlog .artigos .artigoEsquerdo img:hover{
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
}

.pesquisarBlog .artigos .artigoEsquerdo .collection-item{
    height: auto;
}

.pesquisarBlog .artigos .artigoEsquerdo .title{
    font-size: 1.4em;    
    font-weight: bold;
    color: var(--colorBlack);
    cursor: pointer;
}

.pesquisarBlog .artigos .artigoEsquerdo .text{
    font-size:1.2em;
    text-align: justify;
    color: var(--colorBlack);
    cursor: pointer;
}

.pesquisarBlog .artigos .artigoEsquerdo .base{
    width: 100%;
    position: absolute;
    cursor: pointer;
    
}

.pesquisarBlog .artigos .artigoEsquerdo .date{
    font-size: 1em;
    color: var(--colorBlack); 
    margin-right: 20%;
}

.pesquisarBlog .artigos .artigoEsquerdo span .material-icons{
    font-size: 1em;
    color: #ccc;
    margin-right: 20px;
}

.pesquisarBlog .artigos .artigoEsquerdo span .textIcon{
    font-size: 1em;
    color: #ccc;
    font-weight: bold; 
    margin-right: 5px;
}

.pesquisarBlog .artigos .artigoEsquerdo .date:hover, .text:hover, .title:hover{
    transition: all 0.3s;
    opacity: 0.8; 
}

.pesquisarBlog .artigos .artigoEsquerdo .base:hover .textIcon{
    color: #5a0230;
    transition: all 0.3s;
}

.pesquisarBlog .artigos .artigoEsquerdo .base:hover .material-icons{
    color: var(--color2);
    transition: all 0.3s;
}

/*      ARTIGO DIREITO   */
.pesquisarBlog .artigos .artigoDireito{
    width: 30%;
    padding: 0px; 
    box-shadow: #ccc 3px 3px 2px 2px;
    position: sticky;
    top: 300px;
    display: none;
}

.pesquisarBlog .artigos .artigoDireito ul{  
    max-height: 65vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    scrollbar-width: thin;        
    scrollbar-color: #2e012f #5a0230;
    border-radius: 20px;     
}

.pesquisarBlog .artigos .artigoDireito li{  
    padding-bottom: 20px; 
    
}

.pesquisarBlog .artigos .artigoDireito .more{
    font-size: 14pt;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: -20px;
    position: relative;
    background: rgba(245, 245, 245, 1); 
    z-index: 1;
}

.pesquisarBlog .artigos .artigoDireito .collection-itens{
    height: 100px;
    padding: 5px;
    overflow-y: hidden;
}

.pesquisarBlog .artigos .artigoDireito .title{
    width: 100%;
    font-size: 0.9em;
    text-align: center;
    font-weight: bold;
    color: var(--colorBlack);
}

.pesquisarBlog .artigos .artigoDireito .date{
    width: 100%;
    font-size: 0.7em;
    margin-top: 5px;
    color: var(--colorBlack);
    text-align: center;
    float: left;
}


@media (min-width: 400px) {
    
    .pesquisarBlog .artigos .artigoEsquerdo .title{
        font-size: 1.5em;    
        margin: 0px;
        padding: 0px;
        font-weight: bold;
        color: var(--colorBlack);
        cursor: pointer;
    }

    .pesquisarBlog .artigos .artigoEsquerdo .text{
        font-size:1.4em; 
        text-align: justify;
        color: var(--colorBlack);
        cursor: pointer;
    }

    .pesquisarBlog .artigos .artigoEsquerdo .base{
        width: 100%;
        position: absolute;
        cursor: pointer;
        
    }

    .pesquisarBlog .artigos .artigoEsquerdo .date{
        font-size: 1.4em;
        color: var(--colorBlack); 
        margin-right: 20%;
    }

    .pesquisarBlog .artigos .artigoEsquerdo span .material-icons{
        font-size: 2em;
        color: #ccc;
        margin-right: 20px;
    }

    .pesquisarBlog .artigos .artigoEsquerdo span .textIcon{
        font-size: 2em;
        color: #ccc;
        font-weight: bold; 
        margin-right: 5px;
    }
}

@media(min-width: 750px) {
    .pesquisarBlog .artigos .artigoEsquerdo{
        width: 68%;
        height: auto;
        margin: auto;
    }
    /*      ARTIGO DIREITO   */
    .pesquisarBlog .artigos .artigoDireito{
        width: 30%;
        padding: 0px; 
        box-shadow: #ccc 3px 3px 2px 2px;
        position: sticky;
        top: 300px;
        display: block;
    }
}


@media (min-width: 800px) {
    
    .pesquisarBlog .artigos .artigoEsquerdo .title{
        font-size: 1.2em;    
        margin: 0px;
        padding: 0px;
        font-weight: bold;
        color: var(--colorBlack);
        cursor: pointer;
    }

    .pesquisarBlog .artigos .artigoEsquerdo .text{
        font-size:1em; 
        text-align: justify;
        color: var(--colorBlack);
        cursor: pointer;
    }

    .pesquisarBlog .artigos .artigoEsquerdo .date{
        font-size: 1em;
        color: var(--colorBlack); 
        margin-right: 20%;
    }

    .pesquisarBlog .artigos .artigoEsquerdo span .material-icons{
        font-size: 1em;
        color: #ccc;
        margin-right: 20px;
    }

    .pesquisarBlog .artigos .artigoEsquerdo span .textIcon{
        font-size: 1em;
        color: #ccc;
        font-weight: bold; 
        margin-right: 5px;
    }
}

@media(min-width: 1000px) {
    .pesquisarBlog{
        width: 100%;
        min-height: 100vh;
        margin: 0px;
        background: var(--color-background);
    
    }
    
    .pesquisarBlog a{
        text-decoration: none;
        color: var(--colorBlack);
    }
    
    /* PROPAGANDA HEADER */
    .pesquisarBlog .propaganda-header{
        width: 90%;
        height: 100px;
        margin: 10px auto;
    }
    
    .pesquisarBlog .artigos{
        width: 100%;
        min-height: 70vh;
        margin: auto;    
        margin-top: 50px;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        
    }
    
    .pesquisarBlog .artigos ul{
        border: none;
    }
    
    .pesquisarBlog .artigos h4{
        text-align: center;
        margin-left: 5%;
        font-weight: bold;
        
    }
    
    .pesquisarBlog .artigos h5{
        text-align: left;
        margin-left: 5%;
        font-weight: bold;
    }
    
    .pesquisarBlog .artigos .artigoEsquerdo li{ 
        text-align: left;
    }
    
    .pesquisarBlog .artigos .artigoEsquerdo{
        width: 62%;
        height: auto;
        margin-right: 2.5%;
    }
    
    .pesquisarBlog .artigos .artigoEsquerdo img{
        width: 340px;
        height: 100%;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 0.2em;
        max-width: 100%;
        transition: all 0.3s;
        -moz-transform: scale(0.98);
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
    }
    
    .pesquisarBlog .artigos .artigoEsquerdo img:hover{
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    
    .pesquisarBlog .artigos .artigoEsquerdo .collection-item{
        height: 190px;
        max-height: 210px;
    }
    
    .pesquisarBlog .artigos .artigoEsquerdo .title{
        font-size: 1.2em;    
        font-weight: bold;
        color: #000;
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    
    .pesquisarBlog .artigos .artigoEsquerdo .text{
        font-size: 0.8em;
        text-align: justify;
        color: #000;
        cursor: pointer;
        margin-bottom: 10px;
    }
    
    .pesquisarBlog .artigos .artigoEsquerdo .base{
        width: 50%;
        position: absolute;
        cursor: pointer;
        
    }
    
    .pesquisarBlog .artigos .artigoEsquerdo .date{
        font-size: 0.7em;
        color: #000; 
        margin-right: 30%;
    }
    
    .pesquisarBlog .artigos .artigoEsquerdo span .material-icons{
        font-size: 0.7em;
        color: #ccc;
        margin-right: 20px;
    }
    
    .pesquisarBlog .artigos .artigoEsquerdo span .textIcon{
        font-size: 10pt;
        color: #ccc;
        font-weight: bold; 
        margin-right: 5px;
    }
    
    .pesquisarBlog .artigos .artigoEsquerdo .date:hover, .text:hover, .title:hover{
        transition: all 0.3s;
        opacity: 0.8; 
    }
    
    .pesquisarBlog .artigos .artigoEsquerdo .base:hover .textIcon{
        color: #5a0230;
        transition: all 0.3s;
    }
    
    .pesquisarBlog .artigos .artigoEsquerdo .base:hover .material-icons{
        color: var(--color2);
        transition: all 0.3s;
    }
    
    /*      ARTIGO DIREITO   */
    .pesquisarBlog .artigos .artigoDireito{
        width: 30%;
        padding: 0px; 
        box-shadow: #ccc 3px 3px 2px 2px;
    }
    
    .pesquisarBlog .artigos .artigoDireito ul{  
        max-height: 65vh;
        overflow-y: auto;
        scroll-behavior: smooth;
        scrollbar-width: thin;        
        scrollbar-color: #2e012f #5a0230;
        border-radius: 20px;     
    }
    
    
    
    .pesquisarBlog .artigos .artigoDireito li{  
        padding-bottom: 20px; 
        
    }
    
    .pesquisarBlog .artigos .artigoDireito .more{
        font-size: 14pt;
        font-weight: bold;
        margin-top: 5px;
        margin-bottom: -20px;
        position: relative;
        background: rgba(245, 245, 245, 1); 
        z-index: 1;
    }
    
    .pesquisarBlog .artigos .artigoDireito .collection-itens{
        height: 100px;
        padding: 5px;
        overflow-y: hidden;
    }
    
    .pesquisarBlog .artigos .artigoDireito .title{
        width: 100%;
        font-size: 0.9em;
        text-align: center;
        font-weight: bold;
        color: var(--colorBlack);
    }
    
    .pesquisarBlog .artigos .artigoDireito .date{
        width: 100%;
        font-size: 0.7em;
        margin-top: 5px;
        color: var(--colorBlack);
        text-align: center;
        float: left;
    }
    
}

