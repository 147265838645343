@import url('../../../assets/css/style.css');

.homeBlog{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background: var(--color-background);
    min-height: 100vh;
    box-sizing: border-box !important;
    padding: 0;
    margin: 0;
    font-size: 1em;
}

.homeBlog a{
    text-decoration: none;
    color: var(--colorBlack);
}

/* PROPAGANDA HEADER */
.homeBlog .propaganda-header{
    width: 90%;
    height: 100px;
    margin: 10px auto;
}

/* MAIN    */
.homeBlog main{
    width: 100%;
    box-sizing: border-box !important;
    display: flex;
    flex-flow: row wrap;
    gap: 5px;
}

.homeBlog main article:nth-child(1){
    width: 100%;
    box-sizing: border-box !important;
    padding: 0;
    margin: 0;
}

.homeBlog main article:nth-child(1) .container{
    width: 100%;
    box-sizing: border-box !important;
    padding: 0;
    margin: 0;
    position: relative;
    padding: 15px;
    max-width: 80%;
    margin: 0 auto;
}

    /* BOTÃO DIREITA E ESQUERDA*/

.homeBlog main article:nth-child(1) .container .arrow-left, .homeBlog main article:nth-child(1) .container .arrow-right{
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: 0;
    font-size: 30px;
    width: 40px;
    line-height: 250px;
    text-align: center;
    color: #2e012f;
    cursor: pointer;
    border: none;
    background: linear-gradient(to left, transparent 0%, rgba(225, 225, 225, 0.4) 100%);
    opacity: 0.5;
    transition: all 0.5s ease-in-out;
    display: none;
}

.homeBlog main article:nth-child(1) .container .arrow-right{
    left: auto;
    right: 0;
    background: linear-gradient(to right, transparent 0%, white 100%);
}

.homeBlog main article:nth-child(1) .container .indicator{
    width: 100%;
    height: auto;
    position: absolute;
    top: auto;
    left: auto;
    right: auto;
    bottom: 0;
    text-align: center;
    z-index: 5;
}

[type="radio"]:not(:checked)+span:before, [type="radio"]:not(:checked)+span:after {
    border: 2px solid var(--color1) !important;
    background-color: var(--color1) !important;
}

[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:before, [type="radio"].with-gap:checked+span:after {
    border: 2px solid var(--color2) !important;
}

[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:after {
    background-color: var(--color2) !important;
}

.homeBlog main article:nth-child(1) .container .arrow-left:hover, .homeBlog main article:nth-child(1) .container .arrow-right:hover{
    opacity: 1;
}

.homeBlog main article:nth-child(1) .container .gallery-wrapper{
    width: 100%;
    margin: auto;
    overflow-x: scroll;
    background-color: none;
}

.homeBlog main article:nth-child(1) .container .gallery-wrapper::-webkit-scrollbar {
    display: none;
}

.homeBlog main article:nth-child(1) .container .gallery{
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
}

.homeBlog main article:nth-child(1) .container .gallery .item{
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    opacity: 0.6;
    transition: all 0.5s ease-in;
    display: flex;
    flex-flow: row nowrap;
    box-sizing: border-box !important;
    cursor: pointer;
}

.homeBlog main article:nth-child(1) .container .gallery .item:hover{
    opacity: 1;
}

.homeBlog main article:nth-child(1) .container .gallery .current-item{
    opacity: 1;
}

.homeBlog main article:nth-child(1) .container .gallery .item .content-image{
    width: 100%;
    text-align: left;
    padding: 0px;
    margin: auto;
    box-sizing: border-box !important;
}

.homeBlog main article:nth-child(1) .container .gallery .item .content-image img{
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    box-sizing: border-box !important;
}

.homeBlog main article:nth-child(1) .container .gallery .item .content-text{
    width: 90%;
    height: auto;
    padding: 0px;
    margin: 5px 0px;
    color: white;
    text-shadow: 1px 1px 10px var(--colorBlack);
    text-align: justify;
    position: absolute;
    font-size: 1.1em;
}

.homeBlog main article:nth-child(1) .container .gallery .item .content-text .title{
    width: 90%;
    max-height: 50%;
    overflow: hidden;
    text-align: center;
    margin: auto;
    font-weight: bold;
    font-size: 1.2em;
}

.homeBlog main article:nth-child(1) .container .gallery .item .content-text .texto{
    width: 90%;
    max-height: 50%;
    overflow: hidden;
    text-align: justify;
    margin: auto;
    font-size: 1em;
    display: none;
}

    /* LISTAGEM DE ARTIGOS */

.homeBlog main article:nth-child(2){
    padding: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

        /* CARD ARTICLE */

.homeBlog main article:nth-child(2) .card{
    width: 90%;
    margin: auto;
    cursor: pointer;
    font-size: 1.3em;
    overflow: hidden;
}

.homeBlog main article:nth-child(2) .card:hover{
    box-shadow: 3px 5px 20px var(--color2opacity50);
}

.homeBlog main article:nth-child(2) .card .card-content.top{
    padding: 5px;
    text-align: justify;
    overflow: hidden;
}

.homeBlog main article:nth-child(2) .card .card-content{
    font-size: 1em;
    text-align: justify;
    overflow: hidden;
}

.homeBlog main article:nth-child(2) .card .card-image .btn-floating i{
    background-color: var(--color3);
}

.homeBlog main article:nth-child(2) .card .card-image  .card-title{
    font-size: 1.5em;
    text-align: center;
    overflow: hidden;
    color: var(--colorWhite);
    text-shadow: 2px 2px 5px var(--color1);
}

.homeBlog main article:nth-child(2) .card .card-content.top .date{
    font-size: 0.8em;
    float: left;
}

.homeBlog main article:nth-child(2) .card .card-content.top .textIcon, .card .card-content.top .material-icons{
    font-size: 0.8em;
    float: right;
    margin-right: 5px;
}

    /* MENU LATERAL */
.homeBlog main article:nth-child(3){
    display: none;
    width: 25%;
    position: sticky;
    top: 10px;
}

.homeBlog main article:nth-child(3) ul{
    width: 100%;
    margin: 0;
    padding: 0;    
    border: none;
}

.homeBlog main article:nth-child(3) ul li{
    width: 100%;
    margin: 0;
    padding: 10px;    
    background-color: transparent;
    text-align: left;
    font-size: 1em;
    border: none;
    padding-left: 10px; 
}

.homeBlog main article:nth-child(3) ul .categoria{   
    font-size: 1.2em;
    font-weight: bold;
    color: var(--color1);    
    padding-left: 0; 
}

.homeBlog main article:nth-child(3) ul .propaganda{  
    width: 100%; 
    margin: 5px;   
    padding: 0; 
    background-color: var(--color-background);
}



@media (min-width: 400px){
    .homeBlog main article:nth-child(1) .container .gallery .item .content-text{
        width: 85%;
        height: auto;
        padding: 0px;
        margin: 10px 0px;
        text-shadow: 1px 1px 10px var(--colorBlack);
        text-align: justify;
        position: absolute;
        font-size: 1.3em;
    }
    .homeBlog main article:nth-child(1) .container .gallery .item .content-text .title{
        text-align: justify;
    }

    .homeBlog main article:nth-child(1) .container .gallery .item .content-text .texto{
        display: block;
    }

}

@media (min-width: 600px) {
    .homeBlog main article:nth-child(1) .container .gallery .item .content-text{
        width: 85%;
        height: auto;
        padding: 0px;
        margin: 15px 0px;
        text-align: justify;
        position: absolute;
        font-size: 2em;
    }

        /* LISTAGEM DE ARTIGOS */
    .homeBlog main article:nth-child(2){
        padding: 5px;
        gap: 5px;
    }

            /* CARD ARTICLE */

    .homeBlog main article:nth-child(2) .card{
        width: 40%;
    }
}

@media (min-width: 800px) {
    .homeBlog main article:nth-child(1) .container .gallery .item .content-image{
        width: 50%;
        text-align: left;
        padding: 0px;
        margin: 0px;
        box-sizing: border-box !important;
    }
    
    .homeBlog main article:nth-child(1) .container .gallery .item .content-text{
        width: 50%;
        text-align: left;
        position: inherit;
        font-size: 1em;
        color: black;
        text-shadow: none;
    }

        /* LISTAGEM DE ARTIGOS */

    .homeBlog main article:nth-child(2){
        padding: 0px;
        width: 73%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
    }

            /* CARD ARTICLE */

    .homeBlog main article:nth-child(2) .card{
        width: 45%;
        margin: auto;
        cursor: pointer;
        font-size: 1em;
        overflow: hidden;
    }

        /* MENU LATERAL */
    .homeBlog main article:nth-child(3){
        display: block;
        width: 25%;
        position: sticky;
        top: 10px;
    }
}

/* DESKTOP VIEW */
@media(min-width: 1200px) {
    .homeBlog{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        background: var(--color-background);
        min-height: 100vh;
        box-sizing: border-box !important;
        padding: 0;
        margin: 0;
        font-size: 1em;
    }
    
    .homeBlog a{
        text-decoration: none;
        color: var(--colorBlack);
    }
    
    /* PROPAGANDA HEADER */
    .homeBlog .propaganda-header{
        width: 90%;
        height: 100px;
        margin: 10px auto;
    }
    /* MAIN    */
    .homeBlog main{
        width: 100%;
        box-sizing: border-box !important;
        display: flex;
        flex-flow: row wrap;
        gap: 10px;
    }
    
    .homeBlog main article:nth-child(1){
        width: 100%;
        box-sizing: border-box !important;
        padding: 0;
        margin: 0;
    }
    
    .homeBlog main article:nth-child(1) .container{
        width: 100%;
        box-sizing: border-box !important;
        padding: 0;
        margin: 0;
        position: relative;
        padding: 15px;
        max-width: 80%;
        margin: 0 auto;
    }
    
        /* BOTÃO DIREITA E ESQUERDA*/
    
    .homeBlog main article:nth-child(1) .container .arrow-left, .homeBlog main article:nth-child(1) .container .arrow-right{
        position: absolute;
        top: 0;
        left: 0;
        right: auto;
        bottom: 0;
        font-size: 30px;
        width: 40px;
        line-height: 250px;
        text-align: center;
        color: #2e012f;
        cursor: pointer;
        border: none;
        background: linear-gradient(to left, transparent 0%, rgba(225, 225, 225, 0.4) 100%);
        opacity: 0.5;
        transition: all 0.5s ease-in-out;
        display: none;
    }
    
    .homeBlog main article:nth-child(1) .container .arrow-right{
        left: auto;
        right: 0;
        background: linear-gradient(to right, transparent 0%, white 100%);
    }
    
    .homeBlog main article:nth-child(1) .container .indicator{
        width: 100%;
        height: auto;
        position: absolute;
        top: auto;
        left: auto;
        right: auto;
        bottom: 0;
        text-align: center;
        z-index: 5;
    }
    
    [type="radio"]:not(:checked)+span:before, [type="radio"]:not(:checked)+span:after {
        border: 2px solid var(--color1) !important;
        background-color: var(--color1) !important;
    }
    
    [type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:before, [type="radio"].with-gap:checked+span:after {
        border: 2px solid var(--color2) !important;
    }
    
    [type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:after {
        background-color: var(--color2) !important;
    }
    
    .homeBlog main article:nth-child(1) .container .arrow-left:hover, .homeBlog main article:nth-child(1) .container .arrow-right:hover{
        opacity: 1;
    }
    
    .homeBlog main article:nth-child(1) .container .gallery-wrapper{
        width: 97%;
        margin: auto;
        overflow-x: scroll;
        background-color: none;
    }
    
    .homeBlog main article:nth-child(1) .container .gallery-wrapper::-webkit-scrollbar {
        display: none;
    }
    
    .homeBlog main article:nth-child(1) .container .gallery{
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
    }
    
    .homeBlog main article:nth-child(1) .container .gallery .item{
        width: 90%;
        height: 250px;
        flex-shrink: 0;
        opacity: 0.6;
        transition: all 0.5s ease-in;
        display: flex;
        flex-flow: row nowrap;
        box-sizing: border-box !important;
        cursor: pointer;
    }
    
    .homeBlog main article:nth-child(1) .container .gallery .item:hover{
        opacity: 1;
    }
    
    .homeBlog main article:nth-child(1) .container .gallery .current-item{
        opacity: 1;
    }
    
    .homeBlog main article:nth-child(1) .container .gallery .item .content-image{
        width: 50%;
        text-align: left;
        padding: 0px;
        margin: 0px;
        box-sizing: border-box !important;
    }
    
    .homeBlog main article:nth-child(1) .container .gallery .item .content-image img{
        width: 100%;
        height: 100%;
        padding: 0px;
        margin: 0px;
        box-sizing: border-box !important;
    }
    
    .homeBlog main article:nth-child(1) .container .gallery .item .content-text{
        width: 50%;
        text-align: left;
    }
    
    .homeBlog main article:nth-child(1) .container .gallery .item .content-text .title{
        width: 90%;
        max-height: 50%;
        overflow: hidden;
        text-align: justify;
        margin: auto;
        font-weight: bold;
        font-size: 1.2em;
    }
    
    .homeBlog main article:nth-child(1) .container .gallery .item .content-text .texto{
        width: 90%;
        max-height: 50%;
        overflow: hidden;
        text-align: justify;
        margin: auto;
        font-size: 1em;
    }
    
        /* LISTAGEM DE ARTIGOS */
    
    .homeBlog main article:nth-child(2){
        padding: 10px;
        width: 70%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
    }
    
            /* CARD ARTICLE */
    
    .homeBlog main article:nth-child(2) .card{
        width: 40%;
        cursor: pointer;
    }
    
    .homeBlog main article:nth-child(2) .card:hover{
        box-shadow: 3px 5px 20px var(--color2opacity50);
    }
    
    .homeBlog main article:nth-child(2) .card .card-content.top{
        padding: 5px;
        text-align: justify;
        overflow: hidden;
    }
    
    .homeBlog main article:nth-child(2) .card .card-content{
        font-size: 1em;
        text-align: justify;
        overflow: hidden;
    }
    
    .homeBlog main article:nth-child(2) .card .card-image .btn-floating i{
        background-color: var(--color3);
    }
    
    .homeBlog main article:nth-child(2) .card .card-image  .card-title{
        font-size: 1.5em;
        text-align: center;
        overflow: hidden;
        color: var(--colorWhite);
        text-shadow: 2px 2px 5px var(--color1);
    }
    
    .homeBlog main article:nth-child(2) .card .card-content.top .date{
        font-size: 0.8em;
        float: left;
    }
    
    .homeBlog main article:nth-child(2) .card .card-content.top .textIcon, .card .card-content.top .material-icons{
        font-size: 0.8em;
        float: right;
        margin-right: 5px;
    }
    
        /* MENU LATERAL */
    .homeBlog main article:nth-child(3){
        width: 25%;
        position: sticky;
        top: 10px;
    }
    
    .homeBlog main article:nth-child(3) ul{
        width: 100%;
        margin: 0;
        padding: 0;    
        border: none;
    }
    
    .homeBlog main article:nth-child(3) ul li{
        width: 100%;
        margin: 0;
        padding: 10px;    
        background-color: transparent;
        text-align: left;
        font-size: 1em;
        border: none;
        padding-left: 10px; 
    }
    
    .homeBlog main article:nth-child(3) ul .categoria{   
        font-size: 1.2em;
        font-weight: bold;
        color: var(--color1);    
        padding-left: 0; 
    }
    
    .homeBlog main article:nth-child(3) ul .propaganda{  
        width: 100%; 
        margin: 5px;   
        padding: 0; 
        background-color: var(--color-background);
    }
}


