@import url('../../../assets/css/style.css');


.artigoBlog {
    width: 100%;
}

.artigoBlog a{
    text-decoration: none;
    color: #000;
}

.artigoBlog .propaganda-header{
    width: 90%;
    height: 100px;
    margin: 10px auto;
}

.artigoBlog .artigo{
    width: 100%;
    margin: auto;
}

.artigoBlog .artigo:hover{
    opacity: 1 !important;
}

/*          TITULO      */
.artigoBlog .artigo .title{
    width: 100%;
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
    padding-bottom: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    text-shadow: 0px 0px 1px var(--colorBlack);
    padding: 10px;
    color: var(--colorWhite);
}

.artigoBlog .artigo .title:hover{
    opacity: 1 !important;
}

.artigoBlog .artigo .title h3{
    text-align: center;
    font-size: 2em;
    font-weight: bold;
}

.artigoBlog .artigo .title p{
    text-align: center;
    font-size: 1.5em;
}
.artigoBlog .artigo .title h5{
    text-align: center;
    font-size: 1.2em;
}

.artigoBlog .artigo .title h5 span{
    margin: 15px;
}

.artigoBlog .artigo .title h5 a {
    background: none;
    box-shadow: none;
    border: none;
}

.artigoBlog .artigo .title h5 a #share{
    font-size: 1.2em;
    text-align: center;
    color: var(--colorWhite);
}

.artigoBlog .artigo .title h5 .btn:hover #share{
    transform: scale(1.5);
}

/*          CORPO       */
.artigoBlog .artigo .corpo{
    width: 90%;
    margin: auto;
    border-bottom: 1px solid #ccc;
    padding-bottom: 30px;
}

.artigoBlog .artigo  h3:hover{
    opacity: 1 !important;
}

.artigoBlog .artigo .corpo p{
    font-size: 1em;
    text-align: justify;

}

.artigoBlog .artigo .corpo audio {
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.artigoBlog .artigo .corpo iframe {
    width: 100%;
    scroll-behavior: smooth;
    margin-top: 5%;
    margin-bottom: 5%;
}

.artigoBlog .artigo .corpo h3 {
    font-size: 1.5em;
    font-weight: bold;
    text-align: left;
    margin-top: 2%;
    margin-bottom: 2%;

}

.artigoBlog .artigo .corpo img {
    width: 90%;
    height: auto;
    margin-top: 8%;
    margin-bottom: 8%;
    transform: scale(0.8);
    border-radius: none;
    transition: all ease-in-out 0.5s;
}

.artigoBlog .artigo .corpo img:hover {
    transform: scale(1.1);
    border-radius: 1.5em;
}

.artigoBlog .artigo .corpo table {
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.artigoBlog .artigo .corpo ul {
    display: list-item;
    margin-top: 5%;
    margin-bottom: 5%;
}

.artigoBlog .artigo .corpo ul li{
    width: 90%;
    margin: auto;
    text-align: left;
    list-style-type: disc;
}


/*          TAGS          */
.artigoBlog .artigo .curtir{
    width: 50%;
    margin: 10px auto;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
}

.artigoBlog .artigo .curtir h3{
    font-size: 1em;
    
}

.artigoBlog .artigo .curtir a{
    width: auto;
    height: auto;
    margin-right: 10px;
    border-radius: 1.5em;
    background-color: unset;
    border-color: none;
    cursor: pointer;
    transition: all 0.3s;
    
}
.artigoBlog .artigo .curtir .btn:hover #green{
    color: green;    
    transform: scale(1.5);
    
}
.artigoBlog .artigo .curtir .btn:hover  #red{
    color: red;    
    transform: scale(1.5);
    
}
.artigoBlog .artigo .curtir .btn:hover{
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
    opacity: 0.8;

    
}

.artigoBlog .artigo .curtir i{
    font-size: 1.2em;
    margin: 10px;
    color: #ccc;
    transition: all 0.3s;
    transform: scale(0.8);

}


/*          TAGS          */
.artigoBlog .artigo .tags{
    width: 80%;
    margin: 0px auto;
    padding: 10px;
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
    justify-content: center;    
}


.artigoBlog .artigo .tags .chipss{
    width: auto;
    padding: 2px 10px;
    border-radius: 10px;
    background-color: var(--color1);
    color: var(--colorWhite);
    text-transform: uppercase;
    cursor: pointer;
    transition: all ease 0.5s;
}

.artigoBlog .artigo .tags .chipss:hover{
    opacity: 0.9;
    background: var(--color2);
}

@media(min-width: 800px) {
    /*          TITULO      */
    .artigoBlog .artigo .title{
        width: 90%;
        margin: auto;
        border-bottom: 1px solid #ccc;
        margin-bottom: 30px;
        padding-bottom: 10px;
        background-repeat: no-repeat;
        background-size: cover;
        text-shadow: 0px 0px 1px var(--colorBlack);
        padding: 10px;
        color: var(--colorWhite);
        font-size: 1.3em;
    }

    .artigoBlog .artigo .corpo{
        width: 80%;
        margin: auto;
        border-bottom: 1px solid #ccc;
        padding-bottom: 30px;
        font-size: 1.3em;
    }
}

@media(min-width: 1200px) {
    .artigoBlog {
        width: 100%;
    }
    .artigoBlog a{
        text-decoration: none;
        color: #000;
    }
    
    .artigoBlog .propaganda-header{
        width: 90%;
        height: 100px;
        margin: 10px auto;
    }
    
    .artigoBlog .artigo{
        width: 100%;
        margin: auto;
    }
    .artigoBlog .artigo:hover{
        opacity: 1 !important;
    }
    
    /*          TITULO      */
    .artigoBlog .artigo .title{
        width: 85%;
        margin: auto;
        border-bottom: 1px solid #ccc;
        margin-bottom: 30px;
        padding-bottom: 10px;
        background-repeat: no-repeat;
        background-size: cover;
        text-shadow: 0px 0px 1px var(--colorBlack);
        padding: 10px;
        color: var(--colorWhite);
    }
    .artigoBlog .artigo .title:hover{
        opacity: 1 !important;
    }
    
    .artigoBlog .artigo .title h3{
        text-align: center;
        font-size: 40pt;
        font-weight: bold;
        padding: -20px;
    }
    
    .artigoBlog .artigo .title p{
        text-align: center;
        font-size: 16pt;
    }
    .artigoBlog .artigo .title h5{
        text-align: center;
        font-size: 12pt;
    }
    
    .artigoBlog .artigo .title h5 span{
        margin: 30px;
    }
    
    .artigoBlog .artigo .title h5 a {
        background: none;
        box-shadow: none;
        border: none;
    }
    .artigoBlog .artigo .title h5 a #share{
        font-size: 18pt;
        text-align: center;
        color: var(--colorWhite);
    }
    .artigoBlog .artigo .title h5 .btn:hover #share{
        transform: scale(1.5);
    }
    
    /*          CORPO       */
    .artigoBlog .artigo .corpo{
        width: 60%;
        margin-left: 20%;
        border-bottom: 1px solid #ccc;
        margin-bottom: 20px;
        padding-bottom: 30px;
    }
    .artigoBlog .artigo  h3:hover{
        opacity: 1 !important;
    }
    
    .artigoBlog .artigo .corpo p{
        font-size: 16pt;
        text-align: justify;
    
    }
    .artigoBlog .artigo .corpo audio {
        width: 100%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .artigoBlog .artigo .corpo iframe {
        width: 100%;
        scroll-behavior: smooth;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .artigoBlog .artigo .corpo h3 {
        font-size: 20pt;
        font-weight: bold;
        text-align: left;
        margin-top: 2%;
        margin-bottom: 2%;
    
    }
    .artigoBlog .artigo .corpo img {
        width: 90%;
        height: auto;
        margin-top: 8%;
        margin-bottom: 8%;
        transform: scale(0.8);
        border-radius: none;
        transition: all ease-in-out 0.5s;
    }
    .artigoBlog .artigo .corpo img:hover {
        transform: scale(1.1);
        border-radius: 1.5em;
    }
    
    .artigoBlog .artigo .corpo table {
        width: 100%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .artigoBlog .artigo .corpo ul {
        display: list-item;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .artigoBlog .artigo .corpo ul li{
        width: 80%;
        margin-left: 10%;
        text-align: left;
        list-style-type: disc;
    }
    
    /*          TAGS          */
    .artigoBlog .artigo .curtir{
        width: 50%;
        margin-left: 25%;
        margin-top: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 20px;
    }
    
    .artigoBlog .artigo .curtir h3{
        font-size: 14pt;
        
    }
    
    .artigoBlog .artigo .curtir a{
        width: auto;
        height: auto;
        margin-right: 10px;
        border-radius: 1.5em;
        background-color: unset;
        border-color: none;
        cursor: pointer;
        transition: all 0.3s;
        
    }
    .artigoBlog .artigo .curtir .btn:hover #green{
        color: green;    
        transform: scale(1.5);
        
    }
    .artigoBlog .artigo .curtir .btn:hover  #red{
        color: red;    
        transform: scale(1.5);
        
    }
    .artigoBlog .artigo .curtir .btn:hover{
        background-color: #fff;
        border-color: #fff;
        box-shadow: none;
        opacity: 0.8;
    
        
    }
    
    .artigoBlog .artigo .curtir i{
        font-size: 22pt;
        margin: 10px;
        color: #ccc;
        transition: all 0.3s;
        transform: scale(0.8);
    
    }
    
    
    /*          TAGS          */
    .artigoBlog .artigo .tags {
        width: 80%;
        margin: auto;
        display: flex;
        flex-flow: row wrap;
        gap: 10px;
        border-bottom: 1px solid #ccc;
        justify-content: center;
        align-items: center;
        
    }
    
    
    .artigoBlog .artigo .tags .chipss{
        width: auto;
        padding: 2px 10px;
        border-radius: 20px;
        background-color: var(--color1);
        color: var(--colorWhite);
        text-transform: uppercase;
        cursor: pointer;
        transition: all ease 0.5s;
    }
    
    .artigoBlog .artigo .tags .chipss:hover{
        opacity: 0.9;
        background: var(--color2);
    }
}



