.adminBlog{
    margin: 0px;
}

.adminBlog .tabs{
    margin-top: 20px;
    color: #000;
}

.adminBlog .tabs a{
    color: #000;
    font-weight: bold;
}

.adminBlog .exibeTxt{
    font-size: 16pt;
    text-align: justify;

}