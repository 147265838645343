*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root{
    --color-background: rgba(225, 225, 225, 0.4);
    --color1: #2e012f;
    --color2: #5a0230;
    --color2opacity50: hsla(329, 96%, 18%, 0.5);
    --color3: #b0002f;
    --color4: #fe202f;
    --colorWhite: #fff;
    --colorBlack: #000;

    --font-family: 'cursive';
    --font-title: 'title';
    --font-destaque: 'display';
    --font-outra: 'arial';
}

body{
    font-size: 8px;
}

@media (min-width: 600px) {
    body{
        font-size: 8px;
    }
}   
@media (min-width: 800px) {
    body{
        font-size: 12px;
    }
}   

@media (min-width: 1000px) {
    body{
        font-size: 14px;
    }
}   

@media (min-width: 1190px) {
    body{
        font-size: 16px;
    }
}   
@media (min-width: 1400px) {
    body{
        font-size: 18px;
    }
}   